<template>
  <div id="app">
    <div class="pc">
      <!-- 头部 -->
      <div class="header">
        <!-- 上半部分 -->
        <div class="ente">
          <div class="entes">
            <div class="first" @click="dispatchDown()"><img src="../../image/icon.png" alt="">
              <p @click="dispatchDown()">视频解析大师</p>
            </div>
            <div class="second">
              <span class="p1">
                <li>极速下载</li>
                <p>支持多线程,实现闪电般的下载速度</p>
              </span>
              <span class="p2">
                <li>多种下载格式</li>
                <p>包括m3u8、mp4等</p>
              </span>
              <span class="p3">
                <li>多种播放格式</li>
                <p>包括MP4、AVI、MKV、M3u8等格式</p>
              </span>
            </div>
            <div class="sesd">
              <div class="sed_ons" @click="dispatchDown()"><img src="../../image/qrcode.png" alt=""><span>安卓手机扫码下载</span></div>
              <div class="four">
                <div class="fours" @click="dispatchDown()"><img src="../../image/android.png" alt="">
                  <p>安卓下载</p>
                </div>
                <!-- <p class="version_pc">v {{ version }}</p> -->
              </div>
              <div class="ios_download">
                <div class="ios_download_content" @click="openAppstore()"><img src="../../image/iphone_icon.svg" alt="">
                  <p>iOS 下载</p>
                </div>
                <!-- <p class="version_pc">v {{ version }}</p> -->
              </div>
            </div>
          </div>
        
          <!--  -->
          <div class="reds">
            <div class="sind"><img src="../../image/top_left.png" alt=""></div>
            <div class="sinds"><img src="../../image/left_dark.png" alt=""></div>
            <div class="telp">
              <div class="inbox">
                <ul class="pictureList">
                  <li><img src="https://analy.djkte.cn/static/image/bg_stub1.png" width="310" alt="视频解析大师浏览器截图"></li>

                </ul>
              </div>
            </div>
          </div>
        </div>

     
      </div>



      <!-- 字幕 -->
      <div class="offial">
        <div class="offials">Copyright © 2023 <a style="color: white;" href="https://browser.djkte.cn/">视频解析大师</a>
           <a style="color: white;" href="https://beian.miit.gov.cn/">闽ICP备2022000389号-3</a>
            <a style="color: white; margin-left: 20px;">QQ:674460164</a>
          </div>
          
      </div>

    </div>

    <a href="#" class="flex" v-show="mode"><a href="#" class="top"><img src="../../image/top.png" alt="">
        <p>顶部</p>
      </a></a>
    
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      mode: false,
      autoplay: !false,
      muted: !false,
      //页面滚动
      topNavBg: {
        backgroundColor: ''
      },
      fist: !false,
      fists: false,
      second: !false,
      seconds: false,
      three: !false,
      threes: false,
      foust: !false,
      fousts: false,
      version: "1.0.0",

    }
  },
  created() {
    this.link()
    this.handleScroll()
    if (/Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
      navigator.userAgent)) {
      window.location.replace("/mobile");
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    this.fetchVersion()
  },
  methods: {
    fetchVersion() {
      axios({
          method: "get",
          url: "https://browser.djkte.cn/app/office_page.json"
        }).then(red => {
          console.log(red.data.version);
          this.version = red.data.version;
        })
    },

    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop)
      this.scrollTop = scrollTop
      if (this.scrollTop > 200) {
        // console.log("停止播放");
        this.autoplay = false
        this.muted = false
      }
      if (this.scrollTop >= 400) {
        this.mode = !false
        // console.log("展示");
      }
      if (this.scrollTop < 400) {
        this.mode = false
        // console.log("隐藏");
      }
    },
    // 滚动重置
    // beforeDestroy () {
    // 		window.removeEventListener('scroll', this.handleScroll)
    // },
    // 第一个
    btn() {
      this.player.play();
      // console.log("播放中");
      this.fist = false,
        this.fists = !false

      this.second = !false,
        this.seconds = false
      this.three = !false,
        this.threes = false
      this.foust = !false,
        this.fousts = false,
        this.player1.pause();
      this.player2.pause();
      this.player3.pause();
    },
    btns() {
      this.player.pause();
      // console.log("暂停播放");
      this.fist = !false,
        this.fists = false
    },
    // 第二个
    sond() {
      this.player1.play();
      // console.log("播放中");
      this.second = false,
        this.seconds = !false

      this.fist = !false,
        this.fists = false
      this.three = !false,
        this.threes = false
      this.foust = !false,
        this.fousts = false
      this.player2.pause();
      this.player3.pause();
      this.player.pause();
    },
    sonds() {
      this.player1.pause();
      // console.log("暂停播放");
      this.second = !false,
        this.seconds = false
    },
    // 第三个
    sing() {
      this.player2.play();
      // console.log("播放中");
      this.three = false,
        this.threes = !false

      this.fist = !false,
        this.fists = false
      this.foust = !false,
        this.fousts = false
      this.second = !false,
        this.seconds = false
      this.player3.pause();
      this.player1.pause();
      this.player.pause();
    },
    sings() {
      this.player2.pause();
      // console.log("暂停播放");
      this.three = !false,
        this.threes = false
    },

    song() {
      this.player3.play();
      // console.log("播放中");
      this.foust = false,
        this.fousts = !false

      this.fist = !false,
        this.fists = false
      this.three = !false,
        this.threes = false
      this.second = !false,
        this.seconds = false
      this.player2.pause();
      this.player1.pause();
      this.player.pause();

    },
    songs() {
      this.player3.pause();
      // console.log("暂停播放");
      this.foust = !false,
        this.fousts = false
    },
    // 下载
    clickDown() {
      axios({
        method: "get",
        url: "https://browser.djkte.cn/app/office_page.json"
      }).then(red => {
        console.log(red.data.pc);
        window.open(red.data.pc,"_self")
      })
    },
    dispatchDown() {
      var ua = navigator.userAgent.toLowerCase();
      var isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || ua.indexOf('iphone') > -1;
      if (!isiOS) {
        this.clickDown()
      } else {
        this.openAppstore()
      }
    },
    openAppstore() {
      window.open("https://apps.apple.com/us/app/%E8%A7%86%E9%A2%91%E8%A7%A3%E6%9E%90%E5%A4%A7%E5%B8%88/id6450132501")
    },
    link() {
      var _hmt = _hmt || [];
      (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?b358d74cdd2fd9be109abd79a71b65f7";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();
    }

  }

}
</script>
<style src="../style/pc.css"></style>


