import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import  "amfe-flexible";
import axios from 'axios'


Vue.use(Vant);

var _hmt = _hmt || [];
window._hmt = _hmt; // 将_hmt挂载到window下
 (function () {
       var hm = document.createElement("script");
       hm.src = "https://hm.baidu.com/hm.js?d4f9243b0c6a0209db5ce3ca7d7257c2";
       var s = document.getElementsByTagName("script")[0];
       s.parentNode.insertBefore(hm, s);
  })();
Vue.config.productionTip = false

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
